import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: { title: '', desc: '' }
    },
    {
        path: '/dramatist/:keyword',
        name: 'dramatist',
        meta: { title: 'の演劇作品', desc: '演劇作品の公演チラシ、ポスター、趣向を凝らした個性的なチケット等をみることができます。' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/GenreSearch.vue')
    },


    {
        path: '/organizer/:keyword',
        name: 'organizer',
        meta: { title: 'の演劇作品', desc: '演劇作品の公演チラシ、ポスター、趣向を凝らした個性的なチケット等をみることができます。' },
        component: () => import( /* webpackChunkName: "about" */ '../views/GenreSearch.vue')
    },
    {
        path: '/director/:keyword',
        name: 'director',
        meta: { title: 'の演劇作品', desc: '演劇作品の公演チラシ、ポスター、趣向を凝らした個性的なチケット等をみることができます。' },
        component: () => import( /* webpackChunkName: "about" */ '../views/GenreSearch.vue')
    },
    {
        path: '/date/::keyword',
        name: 'date',
        meta: { title: '年に上演された演劇作品', desc: '演劇作品の公演チラシ、ポスター、趣向を凝らした個性的なチケット等をみることができます。' },

        component: () => import( /* webpackChZunkName: "post" */ '../views/GenreSearch.vue')
    },
    {
        path: '/search/:keyword',
        name: 'search',
        meta: { title: 'の演劇作品', desc: '演劇作品の公演チラシ、ポスター、趣向を凝らした個性的なチケット等をみることができます。' },
        component: () => import( /* webpackChZunkName: "post" */ '../views/FreeSearch.vue')
    },

    {
        path: '/dramatist/',
        name: 'dramatist-index',
        meta: { title: '作家一覧', desc: '演劇作品の公演チラシ、ポスター、趣向を凝らした個性的なチケット等をみることができます。' },

        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "about" */ '../views/Index.vue')
    },

    {
        path: '/post/:keyword',
        name: 'post',
        meta: { title: '読み込み中です…', desc: '演劇作品の公演チラシ、ポスター、趣向を凝らした個性的なチケット等をみることができます。' },
        component: () => import( /* webpackChZunkName: "post" */ '../views/Post.vue')
    },

    {
        path: '/about/',
        name: 'about',
        meta: { title: 'このサイトについて', desc: '' },
        component: () => import( /* webpackChZunkName: "post" */ '../views/About.vue')
    },


]

const router = new VueRouter({
    mode: 'history',
    
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {//ページ先頭に戻す
      if (savedPosition) {
        return savedPosition
      } else {
        return { x: 0, y: 0 }
      }
    }
})

export default router