<template>
  <div class="p-main p-main--home">
    <div class="jumbotron jumbotron-fluid p-jumbotron">
      <div class="container">
        <h1 class="display-4">ルイ演劇コレクション</h1>
        <p class="lead">Louis Drama Collection</p>
      </div>
    </div>
    <div class="p-container text-center fz-13 mt-5">

      <router-link v-on:click.native="clickLinkEvent()" to="/dramatist/井上ひさし"
        >「井上ひさし」の作品を掲載しました （5月23日、2022）</router-link
      ><br /><br />
      <router-link v-on:click.native="clickLinkEvent()" to="/dramatist/三島由紀夫"
        >「三島由紀夫」の作品を掲載しました （2月7日、2022）</router-link
      ><br /><br />

      <router-link v-on:click.native="clickLinkEvent()" to="/dramatist/別役実"
        >「別役実」の作品を掲載しました （1月23日、2022）</router-link
      ><br /><br />

      <router-link
        v-on:click.native="clickLinkEvent()"
        to="/dramatist/つかこうへい"
        >「つかこうへい」の作品を掲載しました （12月21日、2021）</router-link
      ><br /><br />

      <!-- <router-link v-on:click.native="clickLinkEvent()" to="/dramatist/清水邦夫"
        >「清水邦夫」の作品を掲載しました （10月18日、2021）</router-link
      ><br /><br /> -->

      <!-- <router-link v-on:click.native="clickLinkEvent()" to="/dramatist/宮本研"
        >「宮本研」の作品を掲載しました （10月10日、2021）</router-link
      ><br /><br /> -->

      <!-- <router-link v-on:click.native="clickLinkEvent()" to="/dramatist/斎藤憐">「斎藤憐」の作品を掲載しました （10月3日、2021）</router-link><br><br> -->

      <!-- <router-link v-on:click.native="clickLinkEvent()" to="/about/">サイトがオープンしました （9月6日、2021）</router-link> -->
    </div>

    <div class="p-container">
      <h2 class="text-center my-5 h6">作品を探す</h2>
      <PanelDramatist></PanelDramatist>
      <PanelOrganizer></PanelOrganizer>
      <PanelDirector></PanelDirector>
      <PanelDate></PanelDate>
    </div>
    <!-- <div class="mt-5 text-center p-sharebtn--home">
            <a href="https://www.instagram.com/louis.drama/" target="_blank">
                <font-awesome-icon :icon="['fab', 'instagram']" size="xs" /></a>
        </div> -->
  </div>
</template>
<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import PanelDramatist from "../components/PanelDramatist";
import PanelOrganizer from "../components/PanelOrganizer";
import PanelDirector from "../components/PanelDirector";
import PanelDate from "../components/PanelDate";
import SnsShare from "../components/SnsShare";

export default {
  components: {
    PanelDramatist,
    PanelOrganizer,
    PanelDirector,
    PanelDate,
    SnsShare,
  },
  data: function() {
    return {};
  },
  created() {
    this.sharePath = "https://louis-drama.com";
    this.siteName = "ルイ 演劇 コレクション";
  },

  methods: {
    window: (onload = function() {
      const spinner = document.getElementById("loading");
      spinner.classList.add("loaded");

      console.log("loader-runner");
    }),
  },
};
</script>
<style lang="scss"></style>
