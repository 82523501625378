<template>
  <section class="p-panel p-panel--search  p-panel--dramatist">
    <h3 class="p-panel--search__heading">作者</h3>
    <ul class="nav nav-pills" :class="{ active: flag }">
      <li class="nav-item" v-for="(item, index) in list" :key="index">
        <router-link
          :to="{ name: 'dramatist', params: { keyword: item } }"
          class="nav-link"
          >{{ item }}</router-link
        >
      </li>
    </ul>
    <div class="p-panel__footer">
      <!-- <span v-if="flag==false" v-on:click='flag=!flag' class="c-readmore c-readmore--allow-down">もっと見る</span> -->
    </div>
  </section>
</template>
<script>
export default {
  data: function() {
    return {
      flag: false,
      list: [
        "秋田雨雀",
        "秋浜悟史",
        "秋元松代",
        "安部公房",
        "有吉佐和子",
        "飯沢匡",
        "石川淳",
        "石澤秀二",
        "石原慎太郎",
        "泉鏡花",
        "市川森一",
        "井上光晴",
        "井上ひさし",
        "内村直也",
        "宇野信夫",
        "遠藤周作",
        "大岡昇平",
        "大岡信",
        "大西信行",
        "岡本綺堂",
        "小山内薫",
        "小幡欣治",
        "加藤道夫",
        "岸田國士",
        "木下順二",
        "久保栄",
        "久保田万太郎",
        "黒井千次",
        "小山祐士",
        "斎藤憐",
        "佐木隆三",
        "里吉しげみ",
        "椎名麟三",
        "清水邦夫",
        "菅原卓",
        "田口竹男",
        "田中澄江",
        "田中千禾夫",
        "谷崎潤一郎",
        "つかこうへい",
        "筒井康隆",
        "鶴屋南北",
        "戸板康二",
        "富岡多恵子",
        "中村光夫",
        "西島大",
        "長谷川四郎",
        "久板栄二郎",
        "福田恆存",
        "別役実",
        "福田善之",
        "堀田善衛",
        "真船豊",
        "森本薫",
        "松本清張",
        "三島由紀夫",
        "水上勉",
        "水木洋子",
        "宮本研",
        "三好十郎",
        "村山知義",
        "八木柊一郎",
        "矢代静一",
        "安岡章太郎",
        "山崎正和",
        "山田太一",
        "山本有三",
        "吉永仁郎",
      ],
    };
  },
};
</script>
<style lang="scss"></style>
