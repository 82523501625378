<template>
    <section class="p-panel p-panel--search">
        <h3 class="p-panel--search__heading">演出</h3>
        <ul class="nav nav-pills" :class='{active:flag}'>
            <li class="nav-item" v-for="(item, index) in list" :key="index">
                <router-link :to="{name:'director', params: { keyword: item }}" class="nav-link">{{item}}</router-link>
            </li>
        </ul>
        <div class="p-panel__footer">
            <span v-if="flag==false" v-on:click='flag=!flag' class="c-readmore c-readmore--allow-down">もっと見る</span>
        </div>
    </section>
</template>
<script>
export default {

    data: function() {
        return {
            flag: false,
            list: ["青山杉作",
"赤石武生",
"秋浜悟史",
"芥川比呂志",
"朝倉摂",
"浅利慶太",
"梓欣造",
"阿部広次",
"安部公房",
"天野二郞",
"荒川哲生",
"有吉佐和子",
"飯沢匡",
"五十嵐康治",
"池田一臣",
"伊沢弘",
"石沢秀二",
"石原慎太郎",
"和泉二郞",
"市川猿之助",
"伊藤大",
"戌井市郎",
"井上思",
"いのうえひでのり",
"今井正",
"今井朋彦",
"岩田豊雄",
"岩淵達治",
"岩村久雄",
"イングマール・ベルイマン",
"内山鶉",
"宇野重吉",
"宇野信夫",
"鵜山仁",
"江守徹",
"遠藤琢郎",
"大木靖",
"大鷹明良",
"大谷亮介",
"大西信行",
"大橋也寸",
"大間知靖子",
"岡倉士朗",
"岡田利規",
"岡部耕大",
"岡村春彦",
"小川絵梨子",
"小幡欣治",
"貝山武久",
"加藤健一",
"加藤新吉",
"加藤武",
"加藤直",
"金子信雄",
"鐘下辰男",
"加納幸和",
"上村聡史",
"亀井光子",
"鴨下信一",
"川口典成",
"河路博",
"川和孝",
"川村毅",
"観世栄夫",
"北林谷栄",
"北村和夫",
"北村文典",
"衣笠貞之助",
"キノトール",
"木下順二",
"木村光一",
"金盾進",
"串田和美",
"國峰眞",
"久保栄",
"久保田万太郎",
"熊林弘高",
"栗山民也",
"栗山昌良",
"黒木仁",
"黒岩亮",
"ケラリーノ・サンドロヴィッチ",
"鴻上尚史",
"兒玉庸策",
"小林和樹",
"小林裕",
"齋藤雅文",
"斎藤憐",
"坂口芳貞",
"坂本長利",
"佐佐木隆",
"佐藤信",
"佐藤優",
"塩見哲",
"篠崎光正",
"島田安行",
"清水邦夫",
"白井晃",
"実相寺昭雄",
"壌晴彦",
"末木利文",
"菅原卓",
"杉田成道",
"鈴木勝秀",
"鈴木完一郎",
"鈴木光枝",
"鈴木裕美",
"瀬川新一",
"関きよし",
"関堂一",
"千田是也",
"高木達",
"高瀬精一郎",
"高瀬久男",
"高橋清祐",
"高橋三千綱",
"高山図南雄",
"滝沢修",
"竹内銃一郎",
"竹内敏晴",
"竹邑類",
"龍岡晋",
"立沢雅人",
"田中千禾夫",
"谷賢一",
"丹野郁弓",
"段田安則",
"知念正文",
"千葉哲也",
"津上忠",
"辻村ジュサブロー",
"手塚敏夫",
"デヴィット・ルヴォー",
"出口典雄",
"戸板康二",
"東野英治郎",
"堂本正樹",
"中城まさお",
"中田浩二",
"中村俊一",
"中村哮夫",
"長岡輝子",
"長崎紀昭",
"長塚圭史",
"成瀬昌彦",
"南原宏治",
"西川信廣",
"西木一夫",
"蜷川幸雄",
"野田雄司",
"野村萬斎",
"野村万蔵",
"萩原朔美",
"八田元夫",
"早川昭二",
"早野寿郎",
"原田一樹",
"坂東玉三郎",
"樋口昌宏",
"平田守",
"広渡常敏",
"深津篤史",
"福田恆存",
"福田善之",
"藤井ごう",
"ふじたあさや",
"藤田敏雄",
"藤原新平",
"本間忠良",
"マキノノゾミ",
"増見利清",
"マックス・ウェブスター",
"松浦竹夫",
"松本祐子",
"眞鍋卓嗣",
"毬谷友子",
"三木のり平",
"水上勉",
"水田晴康",
"水谷良重",
"宮城聰",
"宮島春彦",
"宮田慶子",
"宮永雄平",
"宮本亜門",
"美輪明宏",
"村田大",
"村田元史",
"村山知義",
"森塚敏",
"モーリス･ベジャール",
"八木柊一郎",
"矢代静一",
"安井武",
"安川修一",
"山崎哲",
"山崎正和",
"横内謙介",
"吉沢京夫",
"吉田喜重",
"吉永仁郎",
"米倉斉加年",
"若杉光夫",
"渡辺浩子",
        ]
        }


    }
}
</script>
<style lang="scss">
</style>