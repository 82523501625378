<template>
    <section class="p-panel p-panel--search">
        <h3 class="p-panel--search__heading">上演年</h3>
        <ul class="nav nav-pills" :class='{active:flag}'>
            <li class="nav-item" v-for="(item, index) in list" :key="index">
                <router-link :to="{name:'date', params: { keyword: item }}" class="nav-link">{{item}}</router-link>
            </li>
        </ul>
        <div class="p-panel__footer">
            <span v-if="flag==false" v-on:click='flag=!flag' class="c-readmore c-readmore--allow-down">もっと見る</span>
        </div>
    </section>
</template>
<script>
export default {

    data: function() {
        return {
            flag: false,
            list: ["1885",
"1935",
"1936",
"1938",
"1942",
"1946",
"1948",
"1949",
"1950",
"1951",
"1952",
"1953",
"1954",
"1955",
"1956",
"1957",
"1958",
"1959",
"1960",
"1961",
"1962",
"1963",
"1964",
"1965",
"1966",
"1967",
"1968",
"1969",
"1970",
"1971",
"1972",
"1973",
"1974",
"1975",
"1976",
"1977",
"1978",
"1979",
"1980",
"1981",
"1982",
"1983",
"1984",
"1985",
"1986",
"1987",
"1988",
"1989",
"1990",
"1991",
"1992",
"1993",
"1994",
"1995",
"1996",
"1997",
"1998",
"1999",
"2000",
"2001",
"2002",
"2003",
"2004",
"2005",
"2006",
"2007",
"2008",
"2009",
"2010",
"2011",
"2012",
"2013",
"2014",
"2015",
"2016",
"2017",
"2018",
"2019",
"2020",
"2021",
            ]
        }


    }
}
</script>
<style lang="scss">
</style>