<template>
    <div class="my-3 p-searchform">
        <form @submit.prevent="doSearch">
            <div class="d-flex">
                <div class="p-searchform__main input-group">
                    <input type="text" v-model="q" class="form-control p-searchform__input" placeholder="作品検索">
                </div>
                <div class="p-searchform__sub ml-2 ml-sm-none">
                    <span class="note d-none d-sm-inline-block">作品名・作家・劇団・出演者など</span>
                    <button id="search_button" type="submit" class="btn btn-secondary">検索</button>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
export default {

    //API格納用のプロパティ
    data() {
        return {
            url: 'https://script.google.com/macros/s/AKfycbxjHaI1WMlTAMvnncuNEoYi49xaKPNfVYbKUXdPuM1D8XF-_a-v3kbuzF9R1juBQ9wJ1w/exec',
            items: null,
            loading: true,
            errored: false,
            record: null,
            query: "すべて",
            search_column: null,
            q: null

        }
    },

    created() {

        this.query = this.$route.params.keyword;

        if (this.query != null) {
            this.url = this.url + '?q=' + this.query;
        } else {
            this.query = "すべて"
        }

        this.search_column = this.$route.name;

        //console.log(this.url)


    },

    methods: {

        doSearch: function() {
            if (this.q == null) {
                this.q
            }
            this.$router.push({ name: 'search', params: { keyword: this.q } })
        }


    }

}
</script>