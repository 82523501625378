import Vue from 'vue'
//import Axios from 'axios'

//全体で読み込むscss。
require('@/assets/scss/style.scss');


import App from './App.vue'
import router from './router'

import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)

var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)

Vue.config.productionTip = false

import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: 'https://dummyimage.com/130x120/ccc/999.png&text=Not+Found',
  loading: 'https://dummyimage.com/130x120/dcdcdc/999.png&text=Now loading',
  attempt: 1
});

/*＝＝＝＝＝＝＝＝この下の2行を追加！＝＝＝＝＝＝＝＝*/
import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing);


//GTM
// import VueGtm from 'vue-gtm';

// Vue.use(VueGtm, {
//     id: 'GTM-WXLP63X',//your
// });

//VueAnalytics
import VueAnalytics from 'vue-analytics';

// Configuration VueAnalytics
Vue.use(VueAnalytics, {
  id: 'UA-187610585-1',
  router
});


/* font awesome */
import { library } from '@fortawesome/fontawesome-svg-core'


//apple-alt アイコンをimport
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';//SNS　share用
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'





//利用するアイコンを設定
library.add(fas,fab)

Vue.component('font-awesome-icon', FontAwesomeIcon)

/* font awesome ここまで */

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')



