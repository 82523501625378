<template>
    <section class="p-panel p-panel--search">
        <h3 class="p-panel--search__heading">劇団・主催</h3>
        <ul class="nav nav-pills" :class='{active:flag}'>
            <li class="nav-item" v-for="(item, index) in list" :key="index">
                <router-link :to="{name:'organizer', params: { keyword: item }}" class="nav-link">{{item}}</router-link>
            </li>
        </ul>
        <div class="p-panel__footer">
            <span v-if="flag==false" v-on:click='flag=!flag' class="c-readmore c-readmore--allow-down">もっと見る</span>
        </div>
    </section>
</template>
<script>
export default {

    data: function() {
        return {
            flag: false,
            list: ["青山円形劇場",
"あすなろ",
"新しい芸能室",
"アートボックス",
"アトリエ41",
"安部公房スタジオ",
"いずみたくプロデュース",
"岩田豊雄記念公演",
"印象舞台",
"江戸組",
"NLT",
"円",
"演劇センター",
"演劇座",
"近江楽堂",
"大阪労音",
"岡部企画",
"岡村企画",
"オフィスC&P",
"オンシアター自由劇場",
"櫂",
"梶原プロダクション",
"風",
"風屋敷",
"葛飾区文化振興財団",
"加藤健一事務所",
"金井彰久",
"神奈川芸術劇場",
"可児市文化創造センター",
"企画舎・エフ",
"樹間舎",
"菊五郎劇団",
"キッド・アイラック・ホール",
"紀伊國屋書店",
"紀伊國屋ホール",
"木山潔プロデュース",
"木山事務所",
"京",
"京都小劇場",
"キンダースペース",
"銀座セゾン劇場",
"空間演技",
"葛河思潮社",
"ク・ナウカ",
"久保田万太郎勉強会",
"久保道子プロデュース",
"雲",
"群像座",
"欅",
"芸協",
"芸能座",
"劇書房",
"現代",
"現代演劇協会",
"現代劇センター",
"現代人劇場",
"現代制作舎",
"幸田弘子の会",
"KOKAMI@network ",
"吾一座",
"五月舎",
"ゴーチブラザーズ",
"さいたま芸術劇場",
"さいたまゴールドシアター",
"さいたまネクストシアター",
"坂本長利自主企画",
"桜組",
"櫻社",
"蠍座",
"佐藤正隆事務所",
"サムライ",
"サロン劇場",
"三期会",
"三十人会",
"「座」",
"THE ・ガジラ",
"座・新劇",
"座・陽気な守備隊",
"シアターX",
"四季",
"仕事",
"シス・カンパニー",
"「標」の会",
"新劇交流プロジェクト",
"新生館",
"新作狂言の会",
"春秋団",
"松竹",
"少年社中",
"翔乃会",
"新協劇団",
"新劇合同公演",
"新劇団協議会",
"新国立劇場",
"新国立劇場演劇研修所",
"新宿コマ",
"新宿文化",
"新人会",
"新派",
"ジァン・ジァン",
"JAG",
"自由劇場",
"スウェーデン王立劇場",
"SPAC",
"昴",
"青芸",
"青年芸術劇場",
"青年劇場",
"青年座",
"青年座研究所",
"青俳",
"青林",
"関 弘子企画",
"世田谷パブリックシアター",
"世代",
"蟬の会",
"戦後一幕物傑作選",
"前進座",
"全労災",
"創作座",
"蒼二の会",
"造形",
"鷹の会",
"第三エロチカ",
"地人会",
"中日劇場",
"兆",
"筒井康隆大一座",
"テアトル・エコー",
"手織座",
"手の会",
"テレビ東京",
"テアトロ〈海〉",
"T.P.T.",
"TBS",
"TBSラジオ",
"東演",
"東京演劇アンサンブル",
"TOKYO 演劇フェア'95",
"東京オペラプロデュース",
"東京乾電池",
"東京芸術劇場",
"東京芸術座",
"東京芝居倶楽部",
"東京小劇場",
"東芸劇場自主企画",
"東宝",
"東宝芸能",
"桐朋学園短期大学",
"都民劇場",
"同人会",
"銅鑼",
"ドラマ館",
"ドラマスタジオ",
"ナイロン100℃",
"中城まさお社",
"中根公夫",
"仲間",
"長岡輝子の会",
"二月興業",
"日生劇場",
"蜷川カンパニー",
"NINAGAWA STUＤIO",
"日本オペラ協会",
"野中マリ子企画",
"俳小",
"俳優座",
"俳優座劇場",
"俳優小劇場",
"博品館劇場",
"花組芝居",
"パルコ",
"番衆プロ",
"ひとみ座",
"ひょうご舞台芸術",
"平岡紀子プロデュース",
"2つの部屋",
"Pカンパニー",
"ぴ座",
"ピッコロ劇団",
"BIG FACE",
"ヴィレッジ・T・O",
"フォーリーズ",
"プレイボックス・シアター",
"Bunkamura",
"ぶどうの会",
"文化座",
"文学座",
"文芸",
"変身",
"北條純プロデュース",
"方の会",
"炎座",
"ホリプロ",
"本多企画",
"本多劇場",
"ホンダスタジオ",
"ポイント東京",
"まさかね図案舎",
"街の灯",
"まつもと市民芸術館",
"まにまアート",
"マールイ",
"三島由紀夫上演委員会",
"水谷内助義プロデュース",
"三越劇場",
"水戸芸術館",
"みなと座",
"三好十郎著作刊行会",
"民藝",
"睦月の会",
"明治座",
"冥の会",
"目覚時計",
"木冬社",
"夜想会",
"山彦の会",
"山本安英の会",
"弥々の会",
"結城人形座",
"吉沢演劇塾",
"代々木座",
"立動舎",
"燐",
"ルノー／バロー劇団",
"レ・キャンズ",
"レクラム舎",
"浪曼劇場",
"若州人形座",
"若草",
"早稲田大学演劇研究会"
            ]
        }
    }
}
</script>
<style lang="scss">
</style>