<template>
    <div id="app">
        <input type="checkbox" id="drawerToggle" class="c-drawer__checkbox">
        <div class="p-wrapper">
            <!-- <Header></Header> -->
            <!------------//header------------>
            <main>
                <router-view />
                <!--//p-contents-->
            </main>
            <!------------//main------------>
            <Footer></Footer>
            <!------------//footer------------>
        </div>
        <!------------//wrapper------------>
        <nav class="p-navbar__container--sp  p-container">
            <div class="p-navbar--sp">
                <div class="p-navbar__nav p-navbar_nav--menu p-drawer__togglebtn-wrapper">
                    
                </div>
                <div class="p-navbar__nav p-navbar_nav--logo">
                    <h1 class="p-navbar__heading">
                        <router-link to="/"><img src="@/assets/image/logo.png" alt="Louis Drama Collection" class="p-navbar__logo"></router-link>
                    </h1>
                </div>
                <div class="p-navbar__nav p-navbar_nav--icon">
                    <!-- <a href="#"><i class="p-icon p-icon--user"></i></a> -->
                    <!-- <a href="#"><i class="p-icon p-icon--heart"></i></a> -->
                    <!-- <a href="#" class="p-navbar__cart"><span class="quantity">0</span></a> -->
                    <label for="searchbarToggle" class="mr-4">
                        <font-awesome-icon icon="search" size="lg" :style="{ color: '#666' }" /></label>
                    <nav class="c-drawer__btn-wrapper p-drawer__btn-wrapper">
                        <label for="drawerToggle" class="c-drawer__btn c-drawer__btn--open p-drawer__btn--open"><span></span></label>
                        <label for="drawerToggle" class="c-drawer__btn c-drawer__btn--close"></label>
                    </nav>
                </div>
            </div>
            <input type="checkbox" id="searchbarToggle" class="p-bar__checkbox">
            <div class="p-bar p-bar--light p-bar--searchform">
                <div class="p-bar__inner pb-2">
                    <div class="row">
                        <div class="col">
                            <SearchForm></SearchForm>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        <!------------//navbar------------>
        <label class="c-drawer__overlay p-drawer__overlay" for="drawerToggle"></label>
        <div class="c-drawer p-drawer">
            <div class="p-drawer__inner p-container">
                <div class="p-drawer__closebtn">
                    <label class="" for="drawerToggle">
                        <svg width="32" height="32" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="close"><path fill="none"  stroke-width="1.06" d="M16,16 L4,4"></path><path fill="none"  stroke-width="1.06" d="M16,4 L4,16"></path></svg>
                        </label>
                </div>
                <section class="p-drawernav">
                    <!-- <h3 class="p-drawernav__heading">作品を探す</h3> -->
                    <ul class="p-drawernav__list c-ul--nobullet">
                        <li>
                            <router-link v-on:click.native="clickLinkEvent()" to="/about/">このサイトについて</router-link>
                        </li>
                        <li><a href="https://share.hsforms.com/1aQcoRE9eRD2Dd_Ymvss7Ig5ikvw" target="_blank">お問い合わせ</a></li>
                    </ul>
                </section>
            </div>
        </div>
        <!------------//drawer------------>

        <div id="loading">
          <Loading></Loading>
        </div>
    </div>
    
</template>
<script>
// import Header from './components/Header'
import Footer from './components/Footer'
import SearchForm from './components/SearchForm'
import Loading from './components/Loading'

export default {
    name: 'App',
    components: {
        // Header,
        Footer,
        SearchForm,
        Loading
    },
    methods: {
        clickLinkEvent: function() {

            if (document.getElementById("drawerToggle").checked) {
                // チェックがはいっている
                document.getElementById("drawerToggle").checked = false;

            } else {
                // チェックがはいっていない
                document.getElementById("drawerToggle").checked = true;

            }
        },
        createTitleDesc: function(routeInstance) {
            // タイトルを設定
            if (routeInstance.meta.title) {

                //var setTitle = "「" +this.$route.params.keyword + "」" + routeInstance.meta.title +  '｜ルイ 演劇 コレクション';

                if ( routeInstance.name == "post"){
                    var setTitle_post = routeInstance.meta.title +  '｜ルイ 演劇 コレクション';
                    document.title = setTitle_post;
                }else if(
                    routeInstance.name == "dramatist" ||
                    routeInstance.name == "organizer" ||
                    routeInstance.name == "director" ||
                    routeInstance.name == "date" ||
                    routeInstance.name == "search"

                    ){
                    var setTitle = "" +this.$route.params.keyword + "" + routeInstance.meta.title +  '｜ルイ 演劇 コレクション';
                    document.title = setTitle;
                }else{
                    var setTitle_other = routeInstance.meta.title +  '｜ルイ 演劇 コレクション';
                    document.title = setTitle_other;
                }

                
            } else {
                document.title = 'ルイ 演劇 コレクション｜Louis Drama Collection'
            }

            // メタタグdescription設定
            if (routeInstance.meta.desc) {
                var setDesc = routeInstance.meta.desc ;
                document.querySelector("meta[name='description']").setAttribute('content', setDesc)
            } else {
                document.querySelector("meta[name='description']").setAttribute('content', '1960年代以降の演劇公演チラシ、ポスター、観劇の証しとなるチケット等の個人的なコレクション。趣向を凝らした個性的なチケットには時代の空気とともに、観た舞台への思いがよみがえるチカラがあると考えています。')
            }
        }

    },
    mounted: function() {
        var routeInstance = this.$route;
        this.createTitleDesc(routeInstance);
    },
    watch: {
        //'$route'(routeInstance, from) {
        '$route'(routeInstance) {
            this.createTitleDesc(routeInstance);
            //console.log(from);
        }
    }
}
</script>
<style>
#loading {
  width: 100vw;
  height: 100vh;
  transition: all 0.5s;
  background-color: #fff;
  z-index: 999;
  position: absolute;
  top: 0;
}

#loading .p-loading {
    height: 70vh;

}
#loading .p-loading .p-text--sm{
    display: none;
}

.loaded {
  opacity: 0;
  visibility: hidden;
}
</style>